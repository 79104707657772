import {
  CalendarIcon,
  ChatBubbleLeftRightIcon,
  SparklesIcon,
  UserGroupIcon,
  LinkIcon
} from '@heroicons/react/24/outline';
import { getIntroStatus, markIntroAsCompleted } from 'api/profile';
import FooterNav from 'components/FooterNav';
import { getAnalytics, logEvent } from "firebase/analytics";
import introJs from 'intro.js';
import 'intro.js/introjs.css';
import { toJS } from 'mobx';
import { observer } from 'mobx-react';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import BlueLoader from 'shared/Loader/BlueLoader';
import { TypingEffect } from 'shared/Typing/TypingEffect';
import { authStore } from 'stores/AuthStore';
import { PageType, navigationStore } from 'stores/NavigationStore';


const INTRO_STORAGE_KEY = 'main_page_intro_completed';
const INTRO_NAME = 'main_page_intro';

interface FeatureCardProps {
  icon: React.ReactNode;
  title: string;
  description: string;
  onClick?: () => void;
  gradient: string;
  disabled?: boolean;
  comingSoon?: boolean;
  className?: string;
}

const FeatureCard: React.FC<FeatureCardProps> = ({
  icon,
  title,
  description,
  onClick,
  gradient,
  disabled,
  comingSoon,
  className
}) => (
  <button
    onClick={onClick}
    disabled={disabled}
    className={`group relative p-6 bg-white rounded-2xl shadow-lg
    transition-all duration-300 overflow-hidden text-left w-full 
    ${disabled ? 'opacity-80 cursor-not-allowed' : 'hover:shadow-xl hover:-translate-y-1'}
    ${className}
    `}
  >
    <div className={`absolute inset-0 ${gradient} opacity-10 
      ${!disabled && 'group-hover:opacity-20'} transition-opacity`}
    />
    <div className="relative">
      <div className="bg-white/50 rounded-full w-12 h-12 flex items-center justify-center mb-4">
        {icon}
      </div>
      <div className="flex justify-between items-start">
        <div>
          <h3 className="text-lg font-semibold text-gray-800 mb-2">{title}</h3>
          <p className="text-sm text-gray-600 line-clamp-2">{description}</p>
        </div>
        {comingSoon && (
          <span className="inline-flex items-center px-2.5 py-0.5 rounded-full text-xs font-medium bg-blue-100 text-blue-800">
            Coming Soon
          </span>
        )}
      </div>
    </div>
  </button>
);

const MainPage = () => {
  const navigate = useNavigate();
  const isEnterprise = authStore.currentUser?.profile?.enterprise;
  const userCommunityId = authStore.currentUser?.communityUsers?.[0]?.community?.id;
  console.log('userCommunityId', userCommunityId);
  
  const [isLoading, setIsLoading] = useState(true);

  const checkIntroStatus = async () => {
    try {
      // First check localStorage
      const localStorageStatus = localStorage.getItem(INTRO_STORAGE_KEY);
      if (localStorageStatus === 'true') {
        return true;
      }

      // If not in localStorage, check with backend
      const response = await getIntroStatus(INTRO_NAME);

      if (response) {
        localStorage.setItem(INTRO_STORAGE_KEY, 'true');
      }
      return response;
    } catch (error) {
      console.error('Error checking intro status:', error);
      return false;
    }
  };

  const markIntroAsCompletedFunction = async () => {
    try {
      await markIntroAsCompleted(INTRO_NAME);
      localStorage.setItem(INTRO_STORAGE_KEY, 'true');
    } catch (error) {
      console.error('Error marking intro as completed:', error);
    }
  };

  const trackFeatureClick = (featureName: string) => {
    const analytics = getAnalytics();
    logEvent(analytics, 'feature_click', {
      feature_name: featureName,
      page: 'main'
    });
  };

  const startIntroTour = () => {
    setTimeout(() => {
      introJs()
        .setOptions({
          steps: [
            {
              element: '.header-section',
              intro: 'Welcome to MeetWith! This is your main dashboard to connect, discover, and grow.',
            },
            {
              element: '.feature-grid',
              intro: 'Here are the key features of MeetWith. Explore them to get started!',
            },
            {
              element: '.explore-network-card',
              intro: 'Use this card to explore your network and connect with professionals in your industry.',
            },
            {
              element: '.ai-matching-card',
              intro: 'Use this card to find perfect professional matches with AI technology.',
            },
            {
              element: '.quick-connect-card',
              intro: 'Use this card to swipe through potential business connections and match instantly.',
            },
            {
              element: '.events-meetups-card',
              intro: 'Use this card to join professional events and networking meetups.',
            },
            {
              element: '.chat-connect-card',
              intro: 'Use this card to engage in meaningful conversations with your connections.',
            },
          ],
          showProgress: true,
          showBullets: true,
          exitOnOverlayClick: true,
          disableInteraction: false,
        })
        .oncomplete(() => {
          markIntroAsCompletedFunction();
        })
        .onexit(() => {
          markIntroAsCompletedFunction();
        })
        .start();
    }, 1000);
  };

  useEffect(() => {
    const initializePage = async () => {

      setIsLoading(true);

      navigationStore.setCurrentPage(PageType.MAIN);

      if (navigationStore.shouldRedirectToCommunity() && userCommunityId) {
        navigate(`/community/${userCommunityId}`);
        return;
      }

      const introCompleted = await checkIntroStatus();

      if (!introCompleted) {
       // startIntroTour();
      }

      setIsLoading(false);
    };

    initializePage();
  }, [userCommunityId]);

  if (isLoading) {
    return <div className="flex items-center justify-center min-h-screen">
      <BlueLoader />
    </div>;
  }

  return (
    <div className="min-h-screen bg-gradient-to-br from-blue-50 via-purple-50 to-pink-50">
      {/* Decorative Elements */}
      <div className="absolute inset-0 overflow-hidden pointer-events-none">
        <div className="absolute -top-40 -right-40 w-80 h-80 bg-purple-200 rounded-full opacity-20 blur-3xl"></div>
        <div className="absolute -bottom-40 -left-40 w-80 h-80 bg-blue-200 rounded-full opacity-20 blur-3xl"></div>
      </div>

      {/* Main Content Container - Added mb-20 for mobile spacing */}
      <div className="relative mb-20">
        {/* Header Section */}
        <header className="pt-8 md:pt-16 px-4 text-center header-section">
          <div className="max-w-4xl mx-auto">
            <h1 className="text-3xl md:text-5xl font-bold text-gray-800 mb-4">
              <TypingEffect text="Welcome to MeetWith" speed={50} />
            </h1>
            <p className="text-gray-600 text-sm md:text-lg max-w-2xl mx-auto">
              Connect with professionals, discover opportunities, and grow your network
            </p>
          </div>
        </header>

        {/* Main Features Grid - Added pb-16 for extra bottom spacing */}
        <main className="max-w-7xl mx-auto px-4 py-8 md:py-16 pb-16 feature-grid">
          <div className="grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 gap-6 mb-12">
            <FeatureCard
              className="explore-network-card"
              icon={<UserGroupIcon className="w-6 h-6 text-blue-600" />}
              title="Explore Network"
              description="Discover and connect with professionals in your industry"
              onClick={() => {
                trackFeatureClick('explore_network');
                navigate('/home');
              }}
              gradient="bg-gradient-to-br from-blue-600 to-indigo-600"
            />

            <FeatureCard
              className="ai-matching-card"
              icon={<SparklesIcon className="w-6 h-6 text-purple-600" />}
              title="AI-Powered Search"
              description={isEnterprise
                ? "Search and filter colleagues using our advanced AI matching system"
                : "Use AI to search and discover professionals that match your criteria"}
              onClick={() => {
                trackFeatureClick('ai_matching');
                navigate(isEnterprise ? '/ai-search-colleagues' : '/ai-search-opportunities');
              }}
              gradient="bg-gradient-to-br from-purple-600 to-pink-600"
            />

            <FeatureCard
              className="matches-card"
              icon={<LinkIcon className="w-6 h-6 text-emerald-600" />}
              title="AI-Generated Matches"
              description="View and respond to matches automatically found by our AI matching system"
              onClick={() => {
                trackFeatureClick('matches');
                navigate('/matches');
              }}
              gradient="bg-gradient-to-br from-emerald-600 to-teal-600"
            />
          </div>

          {/* Additional Features */}
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4">
          <FeatureCard
              icon={<UserGroupIcon className="w-6 h-6 text-cyan-600" />}
              title="My Community"
              description="Connect and engage with your professional community"
              onClick={() => {
                trackFeatureClick('my_community');
                navigate(`/community/${userCommunityId}`);
              }}
              gradient="bg-gradient-to-br from-cyan-600 to-blue-600"
            />
            
            <FeatureCard
              className="events-meetups-card"
              icon={<CalendarIcon className="w-6 h-6 text-green-600" />}
              title="Events & Meetups"
              description="Join professional events and networking meetups"
              onClick={() => {
                trackFeatureClick('events_meetups');
                navigate('/activities');
              }}
              gradient="bg-gradient-to-br from-green-600 to-teal-600"
            />

            <FeatureCard
              className="chat-connect-card"
              icon={<ChatBubbleLeftRightIcon className="w-6 h-6 text-amber-600" />}
              title="Chat & Connect"
              description="Engage in meaningful conversations with your connections"
              onClick={() => {
                trackFeatureClick('chat_connect');
                navigate('/chats');
              }}
              gradient="bg-gradient-to-br from-amber-600 to-yellow-600"
            />


            {/* <FeatureCard
              className="quick-connect-card"
              icon={<HandRaisedIcon className="w-6 h-6 text-rose-600" />}
              title="Quick Connect"
              description="Swipe through potential business connections and match instantly"
              onClick={() => {
                trackFeatureClick('quick_connect');
                navigate('/meet-with');
              }}
              gradient="bg-gradient-to-br from-rose-600 to-orange-600"
            /> */}
            {/* <FeatureCard
              icon={<RocketLaunchIcon className="w-6 h-6 text-cyan-600" />}
              title="Growth Opportunities"
              description="Discover business opportunities and partnerships"
              gradient="bg-gradient-to-br from-cyan-600 to-blue-600"
              disabled={true}
              comingSoon={true}
            /> */}
          </div>
        </main>

        {/* Footer - Changed to sticky positioning */}
        <FooterNav className="footer-nav" />
      </div>
    </div>
  );
};

export default observer(MainPage);
