import { getIntroStatus, markIntroAsCompleted } from "api/profile";
import ScrollToTop from "components/ScrollToTop/ScrollToTop";
import { CommunityData } from "interface/communities";
import introJs from "intro.js";
import { observer } from "mobx-react-lite";
import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import BlueLoader from "shared/Loader/BlueLoader";
import { PageType, navigationStore } from 'stores/NavigationStore';
import SpotifyLayout from "./SpotifyLayout/SpotifyLayout";

interface CommunityDetailPageContainerProps {
  communityData: CommunityData | null;
}

const INTRO_NAME = "community_detail_intro";
const INTRO_STORAGE_KEY = "community_detail_intro_completed";

export const CommunityDetailPageContainer: FC<CommunityDetailPageContainerProps> = observer(({ communityData }) => {
  const [isLoading, setIsLoading] = useState(true);
  const thisPathname = useLocation().pathname;

  useEffect(() => {
    navigationStore.setCurrentPage(PageType.COMMUNITY);
  }, []);

  const checkIntroStatus = async () => {
    try {
      // First check localStorage
      const localStorageStatus = localStorage.getItem(INTRO_STORAGE_KEY);
      if (localStorageStatus === 'true') {
        return true;
      }

      // If not in localStorage, check with backend
      const response = await getIntroStatus(INTRO_NAME);
      
      if (response) {
        localStorage.setItem(INTRO_STORAGE_KEY, 'true');
      }
      return response;
    } catch (error) {
      console.error('Error checking intro status:', error);
      return false;
    }
  };

  const markIntroAsCompletedFunction = async () => {
    try {
      await markIntroAsCompleted(INTRO_NAME);
      localStorage.setItem(INTRO_STORAGE_KEY, 'true');
    } catch (error) {
      console.error('Error marking intro as completed:', error);
    }
  };

  const startIntroTour = () => {
    setTimeout(() => {
    introJs()
      .setOptions({
        steps: [
          {
            element: ".header",
            intro: `
              <div class="intro-content">
                <h3 class="text-xl font-bold text-blue-600 mb-3">Welcome to Your Community Hub! 👋</h3>
                <p class="text-gray-700 leading-relaxed">
                  This is your gateway to everything happening in this vibrant community. Discover:
                  <ul class="mt-2 space-y-1 list-disc list-inside">
                    <li>Active community members</li>
                    <li>Upcoming events & activities</li>
                    <li>Shared resources & amenities</li>
                    <li>Group discussions & more!</li>
                  </ul>
                </p>
                <p class="mt-3 text-sm text-gray-500 italic">Let's explore together!</p>
              </div>
            `,
          },
          {
            element: ".action-buttons",
            intro: `
              <div class="intro-content">
                <h3 class="text-xl font-bold text-blue-600 mb-3">Quick Actions Center 🎯</h3>
                <p class="text-gray-700 leading-relaxed">
                  Your toolkit for engaging with the community:
                  <ul class="mt-2 space-y-1 list-disc list-inside">
                    <li>Chat instantly with community members</li>
                    <li>Browse and join exciting activities</li>
                    <li>Find and connect with specific members</li>
                    <li>Stay updated with community events</li>
                  </ul>
                </p>
                <p class="mt-3 text-sm text-gray-500 italic">Click any button to get started!</p>
              </div>
            `,
          },
            // ... other intro steps
        ],
        showProgress: true,
        showBullets: true,
        exitOnOverlayClick: true,
        disableInteraction: false,
      })
      .oncomplete(() => {
        markIntroAsCompletedFunction();
      })
      .onexit(() => {
        markIntroAsCompletedFunction();
      })
      .start();
    }, 1000);
  };

  useEffect(() => {
    const initializePage = async () => {
      setIsLoading(true);
      const introCompleted = await checkIntroStatus();
      if (!introCompleted) {
       // startIntroTour();
      }
      setIsLoading(false);
    };

    initializePage();
  }, []);

  if (isLoading) {
    return (
      <div className="flex items-center justify-center min-h-screen">
        <BlueLoader />
      </div>
    );
  }
  
  return (
    <div className="nc-ListingStayDetailPage">
      <ScrollToTop />
      <SpotifyLayout communityData={communityData} />
    </div>
  );
});

export default CommunityDetailPageContainer;
