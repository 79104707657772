import { updateUserAvatar, updateUserBackgroundImage, updateUserProfile } from 'api/profile';
import { registerWithGoogleProvider } from 'api/register';
import { deleteAccount, getUser } from 'api/user';
import {
    GoogleAuthProvider,
    isSignInWithEmailLink,
    onAuthStateChanged,
    sendSignInLinkToEmail,
    signInWithEmailLink,
    signInWithPopup,
    signOut
} from 'firebase/auth';
import { UpdateProfileDto, User } from 'interface/user.interface';
import _ from 'lodash';
import { makeAutoObservable, runInAction } from 'mobx';
import Smartlook from 'smartlook-client';
import { setAuthToken } from 'utils/setAuthorizationHeader';
import { auth } from '../firebase';
import metadataStore from './MetadataStore';
import { attachUserToCommunity } from 'api/communities';



class AuthStore {
    currentUser: User | null = null;
    loading = true;

    constructor() {
        makeAutoObservable(this);

        // Initialize user state
        this.initUserState();
    }

    async initUserState() {
        onAuthStateChanged(auth, async (firebaseUser) => {
            if (firebaseUser) {
                try {
                    const accessToken = await firebaseUser.getIdToken();
                    setAuthToken(accessToken);
                    const { data: user } = await getUser();
                    metadataStore.fetchMetadata()
                    console.log('Is logged in:', user);

                    runInAction(() => {
                        this.currentUser = user;
                        this.loading = false;
                    });
                    const isNotLocal = window.location.hostname !== 'localhost';
                    if (isNotLocal && user) {
                        // Set user ID in Smartlook after login
                        Smartlook.identify(user.id, { name: user.profile.fullName });
                    }

                } catch (error) {
                    console.error(error);
                    
                    runInAction(() => {
                        this.currentUser = null;
                        this.loading = false;
                    });
                    // Handle sign-out or redirect to login
                }
            } else {
                runInAction(() => {
                    this.currentUser = null;
                    this.loading = false;
                });
                // Handle redirect to login if needed
            }
        });
    }

    async googleSignIn() {
        const provider = new GoogleAuthProvider();
        const result = await signInWithPopup(auth, provider);
        const { data: user } = await registerWithGoogleProvider(result.user);

        if (user) {
            this.currentUser = user;
        }
        // Update user state or navigate post-login
    }

    async logOut() {
        await signOut(auth);
        // this.currentUser = null;
        // Handle post-logout navigation or state cleanup
    }

    async sendSignInLinkToEmailHandler(email: string) {
        window.localStorage.setItem('emailForSignIn', email);
        const actionCodeSettings = {
            url: `${window.location.origin}/sign-in-completed`,
            handleCodeInApp: true,
        };
        await sendSignInLinkToEmail(auth, email, actionCodeSettings);
    }

    isSignInWithEmailLinkHandler(emailLink: string) {
        return isSignInWithEmailLink(auth, emailLink);
    }

    async signInWithEmailLinkHandler(email: string, emailLink: string) {
        const result = await signInWithEmailLink(auth, email, emailLink);
        await registerWithGoogleProvider(result.user);
        window.localStorage.removeItem('emailForSignIn');
        // Handle navigation or state update post-sign-in
        if (result) {
            return true
        }
        return false
    }

    setLoading(status: boolean) {
        this.loading = status;
    }

    async updateProfile(updatedUser: UpdateProfileDto) {
        if (_.isEmpty(this.currentUser)) {
            return
        }

        await updateUserProfile(updatedUser);

        if (this.currentUser && this.currentUser.profile) {
            this.currentUser.profile = {
                ...this.currentUser.profile,
                ...updatedUser,
            };
        }
    }

    deleteAccount = async () => {
        if (this.currentUser) {
            try {
                await deleteAccount();
                await signOut(auth);
                window.location.href = '/login';
            } catch (error) {
                console.error('Error deleting account:', error);
            }
        }
    }

    async updateAvatar(formData: FormData) {
        try {
            const { data: avatarUrl } = await updateUserAvatar(formData);
            if (this.currentUser && this.currentUser.profile) {
                this.currentUser.profile = {
                    ...this.currentUser.profile,
                    avatar: avatarUrl,
                };

                return avatarUrl
            }
        } catch (error) {
            console.error('Error updating avatar:', error);
        }
    }

    async signInWithEmailLink(emailLink: string): Promise<boolean> {
        try {
            if (!isSignInWithEmailLink(auth, emailLink)) {
                return false;
            }

            const email = localStorage.getItem('emailForSignIn');
            if (!email) {
                throw new Error('Email not found. Please try signing in again.');
            }

            const result = await signInWithEmailLink(auth, email, emailLink);
            if (!result.user) {
                return false;
            }

            const { data: user } = await registerWithGoogleProvider(result.user);
            localStorage.removeItem('emailForSignIn');

            // Update current user
            if (user) {
                this.currentUser = user;
            }

            return true;

        } catch (error) {
            console.error('Error signing in with email link:', error);
            throw error;
        }
    }

    async updateBackgroundImage(formData: FormData) {
        try {
            const { data: backgroundImageUrl } = await updateUserBackgroundImage(formData);
            if (this.currentUser && this.currentUser.profile) {
                this.currentUser.profile.bgImg = backgroundImageUrl;
                return backgroundImageUrl;
            }
        } catch (error) {
            console.error('Error updating background image:', error);
            throw error;
        }
    }

    async joinCommunity(community_id: string, join_by?: string) {
        try {
            const { data } = await attachUserToCommunity(community_id, join_by || '');
            
            // Update the currentUser with the new community data
            if (this.currentUser) {
                this.currentUser = {
                    ...this.currentUser,
                    communityUsers: [...(this.currentUser.communityUsers || []), data]
                };
            }
            
            return data;
        } catch (error) {
            console.error('Error joining community:', error);
            throw error;
        }
    }
}

export const authStore = new AuthStore();
