import { trackInviteClick } from 'api/communities';
import { useEffect, useState } from 'react';
import { useNavigate, useParams, useLocation } from 'react-router-dom';

const JoinCommunityPage = () => {
    const { communityId } = useParams();
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        const trackInviteClickFunc = async () => {
            try {
                if (!communityId) {
                    return;
                }
                
                // Send tracking beacon
                const response = await trackInviteClick(communityId, {
                    //   inviteToken,
                    referrer: document.referrer,
                });

                // Store click ID in session storage for conversion tracking
                if (response.data?.id) {
                    sessionStorage.setItem('inviteClickId', response.data.id);
                    sessionStorage.setItem('communityId', communityId);
                }

                // Redirect to community page
                navigate(`/`);
            } catch (error) {
                console.error('Error tracking invite click:', error);
                // Still redirect even if tracking fails
                navigate(`/`);
            } finally {
                setLoading(false);
            }
        };
        if (communityId) {
            const params = new URLSearchParams(location.search);
            const joinBy = params.get('join_by');
            const communityData = {
                communityId,
                joinBy: joinBy || null
            };
            localStorage.setItem('communityData', JSON.stringify(communityData));
            trackInviteClickFunc();
        }
    }, [communityId, location.search]);

    if (loading) {
        return (
            <div className="flex items-center justify-center min-h-screen">
                <div className="animate-spin rounded-full h-8 w-8 border-b-2 border-primary"></div>
            </div>
        );
    }

    return null;
};

export default JoinCommunityPage; 