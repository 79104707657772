import { avatarColors } from "contains/contants";
import React, { FC } from "react";
import PlaceholderSmallH from '../../images/placeholder-small.png';

export interface AvatarProps {
  containerClassName?: string;
  sizeClass?: string;
  radius?: string;
  imgUrl?: string;
  userName?: string;
  hasChecked?: boolean;
  hasCheckedClass?: string;
}

const Avatar: FC<AvatarProps> = ({
  containerClassName = "ring-1 ring-white dark:ring-neutral-900 ",
  sizeClass = "h-6 w-6 text-sm",
  radius = "rounded-full",
  imgUrl = '',
  userName,
  hasChecked,
  hasCheckedClass = "w-4 h-4 -top-0.5 -right-0.5",
}) => {
  const url = imgUrl || "";  
  const name = userName || "";
  const _setBgColor = (name: string) => {
    const backgroundIndex = Math.floor(
      name.charCodeAt(0) % avatarColors.length
    );
    return avatarColors[backgroundIndex];
  };

  const [imgError, setImgError] = React.useState(false);
  
  return (
    <div
      className={`wil-avatar relative flex-shrink-0 inline-flex items-center justify-center text-neutral-100 uppercase font-semibold shadow-inner ${radius} ${sizeClass} ${containerClassName}`}
      style={{ backgroundColor: url && !imgError ? undefined : _setBgColor(name) }}
    >
      {url && !imgError && (
        <>
          {url.endsWith('.svg') ? (
            <object
              className={`inset-0 w-6 h-6 ${radius}`}
              data={url}
              type="image/svg+xml"
              aria-label={name}
              onError={() => setImgError(true)}
            >
              <img
                className={`absolute inset-0 w-full h-full ${radius}`}
                src={PlaceholderSmallH}
                alt={name}
                onError={() => setImgError(true)}
              />
            </object>
          ) : (
            <img
              className={`absolute inset-0 w-full h-full ${radius}`}
              src={url}
              alt={name}
              referrerPolicy="no-referrer"
              onError={(e) => {
                e.currentTarget.src = PlaceholderSmallH; // Fallback image
              }}
            />
          )}
        </>
      )}
      <span className="wil-avatar__name">{imgError || !url ? name[0] : ''}</span>

      {hasChecked && (
        <span
          className={` bg-teal-500 rounded-full text-white text-xs flex items-center justify-center absolute  ${hasCheckedClass}`}
        >
          <i className="las la-check"></i>
        </span>
      )}
    </div>
  );
};

export default Avatar;
